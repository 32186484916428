import useBaseUtils from "~/functions/useBaseUtils";
import useGuruUtils from "~/functions/useInvestorGuruUtils"; // import BasicGuruSearch from "~/components/BasicGuruSearch.vue"
export default defineComponent({
  // components: {
  //   BasicGuruSearch,
  // },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      mainTier = _useBaseUtils.mainTier,
      dev = _useBaseUtils.dev,
      freeTier = _useBaseUtils.freeTier,
      I18nFn = _useBaseUtils.I18nFn;
    var _useGuruUtils = useGuruUtils(),
      trackInvestors = _useGuruUtils.trackInvestors,
      permIdObj = _useGuruUtils.permIdObj;
    return {
      refCode: refCode,
      mainTier: mainTier,
      dev: dev,
      trackInvestors: trackInvestors,
      permIdObj: permIdObj,
      freeTier: freeTier,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      investorGroupTitles: {
        1: "GARP",
        // "Growth at a Reasonable Price (GARP)",
        2: "Value",
        3: "Aggressive Growth",
        4: "Other"
      },
      headers: [{
        align: "start",
        sortable: true,
        value: "investorName",
        text: this.I18nFn("Investor / Fund Name")
      }],
      footerProps: {
        itemsPerPageOptions: [10, 20, -1]
      }
    };
  }
});